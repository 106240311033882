.tabs {
  margin-left: 0;
  display: block;
  @include tablet {
    display: flex;
  }
  @include desktop {
    margin-left: -#{$gap * 0.5};
  }

  ul {
    display: block;
    text-align: left;
    @include tablet {
      display: flex;
      text-align: center;
    }
  }

  li {
    margin-right: 0;
    margin-bottom: 1px;
    @include tablet {
      margin-right: #{$column-gap  * 0.5};
      margin-bottom: 0px;
    }
    @include desktop {
      margin-right: $column-gap;
    }

    &:last-child {
      margin-right: 0;
    }

    a {
      background-color: $tabs-toggle-link-background-color;
    }
  }
}
