
.column {
  padding: 1rem ($gap * 0.5);
  @include desktop {
    padding: 2rem $gap;
  }
}

.columns {
  margin-left: -($gap * 0.5);
  margin-right: -($gap * 0.5);
  margin-top: -(1rem);
  @include desktop {
    margin-left: -($gap);
    margin-right: -($gap);
    margin-top: -(2rem);
  }
}

.columns:last-child {
  margin-bottom: -1rem;
  @include desktop {
    margin-bottom: -2rem;
  }
}

.columns:not(:last-child) {
  margin-bottom: calc(1.5rem - 1rem);
  @include desktop {
    margin-bottom: calc(1.5rem - 2rem);
  }
}
