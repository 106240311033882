.modular--list {
  margin-top: -1rem;
  margin-bottom: 1rem;
  li {
    border-bottom: solid 1px $grey-lighter;
    font-weight: 600;
    padding: 1rem 0;
    font-family: $family-sans-serif;
    @include desktop {
      padding: 2rem 0;
    }
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}
