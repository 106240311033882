@use "sass:math";

.footer {
  color: $grey-medium;
  margin-top: 0;
  @include desktop {
    margin-top: 4rem;
  }

  .a4y-logo {
    margin-top: 4rem;

    svg {
      height: 21px;
      width: 29px;
      position: relative;
      top: 3px;
    }

    &:before,
    &:after {
      background-color: #b3b3b3;
      content: '';
      display: inline-block;
      height: 1px;
      width: 36px;
    }
  }

  .a4y-copyright {
    font-size: $size-7;
  }

  .icon {
    width: $gap;
    margin: 0 0 0 -#{$gap*0.25};
    text-align: center;
  }

  .content a {
    color: inherit;

    &:after {
      border-color: transparent;
      bottom: 0;
    }

    &:hover {
      color: $green;
    }

    &:hover:after {
      border-color: $green;
    }
  }

  .columns {
    width: 100%;
    margin: 0;
    padding: 0;
    @include desktop {
      width: auto;
      margin-left: -#{$gap};
      margin-right: -#{$gap};
      margin-bottom: 0;
      padding: 1rem #{$gap};
    }
  }

  .columns > .column {
    //padding: 1rem #{$gap / 2};
    display: flex;
    align-items: center;

    flex-grow: 1;
    flex-basis: auto;
    line-height: 1.2;
    @include desktop {
      padding: 3rem #{$gap * 0.25};
    }
  }

  .column.is-10-desktop {
    width: 100%;
    padding: 0;
    @include desktop {
      margin-left: 0;
    }
    @include widescreen {
      margin-left: -#{$gap};
    }
  }

  .modular--columns {
    background-color: #f5f5f5;
    position: relative;
    @include widescreen {
      max-width: percentage(math.div(10, 12));
    }

    @include desktop {
      &:before {
        background-color: #f5f5f5;
        content: '';
        display: block;
        width: 50%;
        position: absolute;
        z-index: -1;
        height: 100%;
        left: -50%;
      }
    }
  }

  .footer--menu {
    align-items: center;
    display: flex;
    margin-bottom: 1rem;
  }

  .footer--menu--item {
    display: inline-block;
    &:after {
      background: $grey-lighter;
      content: '';
      height: 16px;
      width: 1px;
      display: inline-block;
      margin: 0 #{$gap * 0.25};
      position: relative;
      top: 2px;
    }

    &:last-child:after {
      display: none;
    }
  }

  .footer--menu--item__link {
    color: $grey-medium;
    transition: $transition;

    &:hover, &:focus {
      color: $grey-dark;
    }

    &.is-active {
      color: $grey-dark;
      font-weight: 600;
    }
  }

  .copyright {
    margin-top: 1rem;
    background-color: #f5f5f5;
    position: relative;
    padding: 2rem #{$gap * 0.5};
    @include widescreen {
      max-width: percentage(math.div(10, 12));
      padding: 2rem $gap;
      margin: 0 -#{$gap}
    }

    @include desktop {
      &:before {
        background-color: #f5f5f5;
        content: '';
        display: block;
        width: 50%;
        position: absolute;
        height: 100%;
        left: -50%;
        top: 0;
      }
    }

    &:after {
      background-color: $primary;
      content: '';
      display: block;
      height: 1rem;
      width: calc(100% + 50% - #{$column-gap * 0.5});
      position: absolute;
      bottom: -1rem;
      left: -50%;
    }
  }
}
