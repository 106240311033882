.modular--columns {
  margin-bottom: 1rem;

  .column h4 {
    font-size: $size-4;
    font-family: $family-sans-serif;
    font-weight: 400;
    margin-bottom: 0.5rem;
    @include widescreen {
      font-size: $size-2;
      margin-bottom: 1rem;
    }
  }

  .columns {
    display: flex;
    flex-wrap: wrap;
    @include widescreen {
      flex-wrap: inherit;
    }
  }

  .column {
    border-top: solid 1px $white;
    border-bottom: solid 1px $grey-lightest;
    min-width: 50%;
    @include tablet {
      border-top: none;
      border-bottom: none;
      min-width: 20%;
      position: relative;
    }
    @include widescreen {
      min-width: inherit;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &.has-background-dark .column {
    border-top-color: #505050;
    border-bottom-color: #2d3033;
    @include tablet {
      padding-top: 1rem;
      padding-bottom: 1rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      &:after {
        background-color: #535E68;
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    &:last-of-type:after {
      display: none;
    }
  }

  .content + .columns {
    margin-top: 1rem;
  }

  .columns + .content {
    margin-top: 2rem;
  }

  &.theme--none:first-child {
    margin-top: -2rem;
    @include tablet {
      margin-top: -5rem;
    }
    @include desktop {
      margin-top: -6rem;
    }
  }

  &.theme--none .column {
    border-top: none;
    border-bottom: none;
    line-height: 1.2;
    font-size: $size-7;
    font-weight: 600;
    min-width: inherit;
    @include widescreen {
      font-size: $size-6;
    }
  }
}
