.modular--text-two-columns {
  background-color: red;
  text-align: center;
  margin-bottom: 1rem;

  .column:first-of-type {
    border-bottom: solid 1rem #fff;

    @include desktop {
      border-bottom: none;
    }
  }

  //.content img {
  //  width: 100%;
  //}

  &--gallery--image {
    border-right: solid 1rem #fff;
    border-left: solid 1rem #fff;
    width: auto;
    height: auto;
    padding: 0;

    img {
      object-fit: cover;
      height: 100%;
    }
  }
}
