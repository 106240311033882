$scrolling-height: 4rem;
body.has-navbar-fixed-top,
.navbar > .container,
.navbar-burger,
.navbar-item img,
.navbar,
.navbar-brand, .navbar-tabs {
  transition: $transition;
}


body.has-navbar-fixed-top {
  padding-top: $scrolling-height;
  @include desktop {
    padding-top: $navbar-height;
  }
}

body.has-navbar-fixed-top.is-scrolling {
  padding-top: $scrolling-height;
}

.navbar-burger {
  height: $scrolling-height;
  @include desktop {
    height: $navbar-height;
  }
}

.is-scrolling .navbar-burger {
  height: $scrolling-height;
}

.navbar > .container,
.navbar,
.navbar-brand, .navbar-tabs {
  min-height: $scrolling-height;
  @include desktop {
    min-height: $navbar-height;
  }
}

.is-scrolling .navbar > .container,
.is-scrolling .navbar,
.is-scrolling .navbar-brand, .navbar-tabs {
  min-height: $scrolling-height;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-brand .navbar-item {
  display: block;
}

.navbar-item svg {
  display: block;
  max-height: 2rem;
  height: 2rem;
  transition: $transition;
  position: relative;
  left: 2px;
  @include desktop {
    max-height: $navbar-item-img-max-height;
    height: $navbar-item-img-max-height;
  }

  &:hover .logo {
    fill: $dark;
  }

  .logo,
  .font {
    transition: $transition;
  }
}

.is-scrolling .navbar-item svg {
  max-height: 2rem;
  height: 2rem;

  .font {
    opacity: 0;
  }
}

.navbar > .container .navbar-menu, .container > .navbar .navbar-menu {
  margin-right: 0;
  @include desktop {
    margin-right: 4rem;
  }
}

.navbar > .container .navbar-brand, .container > .navbar .navbar-brand {
  margin-left: 0;
  margin-right: 0;
}

.navbar-item {
  padding: 0.5rem #{$column-gap * 0.5};
  @include desktop {
    padding: 0.5rem #{$column-gap * 0.5};
  }
  @include widescreen {
    padding: 0.5rem #{$column-gap};
  }
}

.navbar-menu .navbar-item {
  font-size: 18px;
  @include desktop {
    display: inline-block;
    padding: 0 #{$column-gap};
  }
  @include widescreen {
    padding: 0 #{$column-gap};
  }

  &.is-active {
    background-color: $primary;
    font-weight: 600;
    color: $white;
    @include desktop {
      background-color: transparent;
      color: inherit;
      &:after {
        background-color: $primary;
        content: '';
        display: block;
        height: 3px;
        width: calc(100% + 10px);
        position: relative;
        bottom: -5px;
        left: -5px;
      }
    }
  }
}

.navbar-menu {
  padding: 0;
  @include desktop {
    align-items: center;
    display: flex;
  }
}

.navbar-burger span {
  transition-duration: 0.3s;
  left: calc(50% - 18px);
}

.navbar-burger span:nth-child(2) {
  display: none;
}

.navbar-burger span:nth-child(1),
.navbar-burger span:nth-child(3) {
  color: $dark;
}

.navbar-burger span:nth-child(1) {
  height: 3px;
  width: 40px;
  top: calc(50% - 4px);
}

.navbar-burger span:nth-child(3) {
  height: 3px;
  width: 30px;
  top: calc(50% + 5px);
}

.navbar-burger.is-active span:nth-child(1),
.navbar-burger.is-active span:nth-child(3) {
  width: 30px;
}


.navbar-burger {
  width: $navbar-height * 0.5;

  @include desktop {
    margin-right: #{$gap * 0.5};
  }

  @include widescreen {
    margin-right: #{$gap};
  }

  &.is-hidden-touch {
    @include desktop {
      display: flex;
    }
  }

  &.is-active,
  &:hover {
    background-color: rgba(204, 204, 204, 0.2);
  }
}

.navbar > .container .navbar-brand, .container > .navbar .navbar-brand {
}

.inner-container {
  @include desktop {
    width: calc(100% + #{2* $column-gap});
    align-items: stretch;
    display: flex;
  }
  @include widescreen {
    margin-left: -#{$column-gap};
    margin-right: -#{$column-gap};
  }
}
