.content {
  .has-background-dark & {
    h1, h2, h3, h4, h5, h6 {
      color: $white;
      margin-bottom: 1rem;
    }

    h5 {
      color: $primary;
      font-size: $size-6;
      margin-bottom: 0.5rem;
    }

    .is-size-3 a {
      color: $white;

      &:after {
        border-bottom-color: transparent;
      }
      &:hover:after {
        border-bottom-color: $white;
      }
    }
  }

  .has-text-primary-light {
    color: var(--primary-color--light);
  }

  iframe {
    margin-bottom: 1rem;
    border: solid 1px #ADBE65;
  }

  .has-background-dark & a {
    color: #fff;
  }

  a {
    color: #c0cc87;
    font-weight: 600;
    display: inline-block;
    position: relative;
    transition: $transition;

    &:hover {
      color: $primary;

      &:after {
        border-color: $primary;
      }
    }

    &:after {
      border-bottom: solid 1px #c0cc87;
      content: '';
      transition: $transition;
      display: inline-block;
      position: absolute;
      width: 100%;
      bottom: 6px;
      left: 0;
    }
  }
}
