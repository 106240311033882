
.modular--team .columns > .column {
  padding: 0;
}

.modular--team .columns > .column li {
  border-top: solid 1px $white;
  border-bottom: solid 1px $grey-lightest;
  padding: 1rem #{$gap * 0.5};
  @include desktop {
    padding: 2rem #{$gap};
  }
  &:first-child {
    border-top: 0;
  }
  &:last-child {
    border-bottom: none;
  }
}

.modular--team__name {
  line-height: 1.3;
  overflow: hidden;
}

.modular--team__name strong {
  font-size: $size-4;
  @include desktop {
    font-size: $size-5;
  }
}

.modular--team__position {
  font-weight: 300;
}

.modular--team__content {
  display: none;
  margin-left: 48px;
  //@include desktop {
  //  display: none !important;
  //}
}

.modular--team__content .team--content,
.modular--team__content .team--image {
  transition: $transition;
  margin-top: 1rem;
  @include desktop {
    margin-top: 2rem;
  }
}

.modular--team-image .team--image[data-team-id],
.modular--team-text .team--content[data-team-id] {
  transition: $transition;
  //position: absolute;
  //width: 100%;
  //height: 100%;
  //top: 0;
  //left: 0;
  //opacity: 0;
  //
  //&.is-active {
  //  opacity: 1;
  //}
  //display: none;
}

//.modular--team-image .team--image[data-team-id="1"],
//.modular--team-text .team--content[data-team-id="1"] {
//  //position: relative;
//  display: block;
//}


//.modular--team-image,
//.modular--team-text {
//  position: relative;
//  display: none;
//  @include desktop {
//    display: block;
//  }
//}
//.modular--team-text .content {
//  position: relative;
//}


.modular--team {
  margin-bottom: 1rem;

  //.team--image {
  //  @include desktop {
  //    text-align: right;
  //  }
  //}

  .column.is-4 {
    padding: 0 0 0 #{$gap*0.25};
    //padding: 0;
    position: relative;
  }

  li {
    color: $grey-medium;
    cursor: pointer;
    padding: 2.5rem 0;

    strong {
      color: $grey-medium;
      display: block;
      font-size: $size-4;
    }

    svg {
      float: left;
      height: 42px;
      width: 32px;
      margin-right: 16px;
      margin-top: 2px;
    }

    svg path {
      fill: $white;
      transition: $transition;
    }

    &:hover,
    &.is-active {
      color: $grey-darker;

      strong {
        color: $grey-darker;
      }

      svg path {
        fill: $primary;
      }
    }

    &:first-child {
      //padding-top: 0.5rem;
    }

    &:last-child {
      border-bottom: none;
      //padding-bottom: 0;
    }
  }
}

//.team--image[data-team-id],
//.team--content[data-team-id] {
//  //transition: $transition;
//  //position: absolute;
//  //width: 100%;
//  //height: 100%;
//  //top: 0;
//  //left: 0;
//  //opacity: 0;
//  margin-top: 1rem;
//  margin-left: 48px;
//  opacity: 1;
//  display: none;
//
//  &.is-active {
//    opacity: 1;
//    display: block;
//  }
//}
//
//.team--image[data-team-id="1"],
//.team--content[data-team-id="1"] {
//  position: relative;
//}
