html {
  font-size: 16px;
  @include tablet {
    font-size: 18px;
  }
  @include desktop {
    font-size: $body-size;
  }
}
//
//body {
//  border: solid $body-border-width/4 #fff;
//  border-top-width: $body-border-width;
//  background-color: $grey-lightest;
//  min-height: 100%;
//  margin: 0;
//  padding: 0;
//  display: flex;
//  flex-direction: column;
//  -webkit-text-size-adjust: 100%;
//  @include desktop {
//    border-width: $body-border-width/2;
//    border-top-width: $body-border-width;
//  }
//  @include widescreen {
//    border-width: $body-border-width;
//  }
//}
//
//a, button {
//  transition: $transition;
//}

strong {
  font-weight: 600;
  .has-background-dark & {
    color: $white;
  }
}
