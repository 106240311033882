////////////////////////////////////////////////
////////////////////////////////////////////////
// 1. Initial variables

// Colors
$red: #990000;
$grey-darker: #333333;
$grey-dark: #383f47;
$grey-medium: #666666;
$grey-light: #999999;
$grey-lighter: #CCCCCC;
$grey-lightest: #EFEFEF;
$green: #94c01e;

// Link colors
$link: $grey-dark;
$link-invert: $white;
$link-visited: $grey-dark;
$link-hover: $grey-darker;
$link-hover-border: $grey-lightest;
$link-focus: $grey-darker;
$link-focus-border: $grey-lightest;
$link-active: $grey-darker;
$link-active-border: $grey-dark;

// General colors
$border: $grey-lighter;

// Typography
$family-sans-serif: 'OpenSans', sans-serif;
$family-sans-serif-content: 'Nunito', sans-serif;

$size-1: 2.1rem; // 42px
$size-2: 1.7rem; // 34px
$size-3: 1.35rem; // 27px
$size-4: 1.2rem; // 24px
$size-5: 1rem; // 20px
$size-6: 0.9rem; // 18px
$size-7: 0.8rem; // 16px

// Body
$body-family: $family-sans-serif-content;
$body-size: 20px;
$body-line-height: 1.8;
$body-color: $grey-darker;

// Responsiveness
$gap: 44px;
$tablet: 769px;
$desktop: 1148px + (2 * $gap);
$widescreen: 1848px + (2 * $gap);
$widescreen-enabled: true;
$fullhd-enabled: false;


// Miscellaneous
$radius: 0px;

////////////////////////////////////////////////
////////////////////////////////////////////////
// 2. Primary colors
$primary: $green;
$primary-invert: $white;
$link-color: $grey-dark;

$dark: $grey-dark;
$dark-invert: $white;


////////////////////////////////////////////////
////////////////////////////////////////////////
// 3. Applied variables

// Link colors

// Text colors
$text-strong: $black;

////////////////////////////////////////////////
////////////////////////////////////////////////
// 4. Lists and maps


////////////////////////////////////////////////
////////////////////////////////////////////////
// 5. Custom variables
//$body-border-width: 5rem;
//$content-padding: 5rem;
//$input-placeholder-color: $grey-medium;
$transition: all 0.3s ease-in-out;

////////////////////////////////////////////////
////////////////////////////////////////////////
// 6. Partial overrides.

// Form
//$input-background-color: transparent;
//$input-border-color: $grey-lighter;

// Navbar
$navbar-height: 8rem;
$navbar-item-color: #666;
$navbar-item-hover-color: $dark;
$navbar-item-active-color: $dark;
$navbar-item-hover-background-color: transparent;
$navbar-item-img-max-height: 72px;

// Footer
$footer-background-color: $white;

// Columns
$column-gap: $gap;

// Tabs
$tabs-link-color: $white;
$tabs-link-hover-color: $white;
$tabs-link-padding: 0 #{$gap * 0.5};

$tabs-toggle-link-background-color: $dark;
$tabs-toggle-link-border-color: $dark;
$tabs-toggle-link-border-style: solid;
$tabs-toggle-link-border-width: 1px;
$tabs-toggle-link-hover-background-color: #555;
$tabs-toggle-link-hover-border-color: $dark;
$tabs-toggle-link-radius: $radius;
$tabs-toggle-link-active-background-color: $primary;
$tabs-toggle-link-active-border-color: $primary;
$tabs-toggle-link-active-color: $white;


// Title
$title-color: $grey-darker;

// Card
//$card-shadow: none;

// Button
//$button-color: $white;
//$button-background-color: $grey-dark;
//$button-border-color: $grey-darker;
//$button-hover-color: $white;
//$button-hover-background-color: $grey-darker;
//$button-hover-border-color: $black;
//
//$button-disabled-background-color: $grey-lighter;
//$button-disabled-border-color: $grey-medium;
//$button-disabled-shadow: none;
//$button-disabled-opacity: 1;
//
//$button-focus-color: $white;
//$button-focus-border-color: $black;
//$button-focus-box-shadow-size: 0;
//$button-focus-box-shadow-color: transparent;
//
//$button-active-color: $white;
//$button-active-border-color: $black;
