.has-background-dark,
main .modular.modular--is-first:before {
  background-color: var(--dark-background) !important;
}

.navbar-menu .navbar-item.is-active {
  background-color: var(--primary-color);
}

@media screen and (min-width: 1236px) {
  .navbar-menu .navbar-item.is-active {
    background-color: rgba(0, 0, 0, 0);
  }
}

.tabs.is-toggle li.is-active a,
main .modular.modular--is-first:after,
main .modular:last-child:after,
.footer .copyright:after {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.navbar-menu .navbar-item.is-active:after {
  background-color: var(--primary-color);
}

.navbar-item svg .logo,
.modular--team li:hover svg path,
.modular--team li.is-active svg path,
.icon path,
#toBottom:hover svg path,
#toTop:hover svg path {
  fill: var(--primary-color);
}

.has-background-dark .content h5,
.content a {
  color: var(--primary-color--light);
}

.content a:after {
  border-color: var(--primary-color--light);
}

.has-text-primary {
  color: var(--primary-color) !important;
}

.footer .content a:hover,
.footer .content a:focus {
  color: var(--primary-color);
}

.content a:hover:after,
.content a:focus:after,
.content iframe,
.footer .content a:hover:after {
  border-color: var(--primary-color);
}

.content a:hover,
.content a:focus {
  color: var(--primary-color);
}

*::selection {
  background-color: var(--primary-color);
  color: #fff;
}

*:focus {
  outline-color: var(--primary-color);
}

.default-st0 {
  fill: var(--primary-color--light) !important;
}

.default-st1 {
  fill: var(--primary-color) !important;
}
