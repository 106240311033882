@font-face {
  font-family: 'OpenSans', sans-serif;
  src: url('fonts/Open_Sans/OpenSans-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'OpenSans', sans-serif;
  src: url('fonts/Open_Sans/OpenSans-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'OpenSans', sans-serif;
  src: url('fonts/Open_Sans/OpenSans-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: 'Nunito', sans-serif;
  src: url('fonts/Nunito/Nunito-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Nunito', sans-serif;
  src: url('fonts/Nunito/Nunito-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Nunito', sans-serif;
  src: url('fonts/Nunito/Nunito-SemiBold.ttf');
  font-weight: 600;
}
