.hero {
  .columns .is-10 {
    position: relative;
    z-index: 2;
    @include tablet {
      background-color: rgba(255, 255, 255, 0.6);
    }
  }

  .title {
    position: relative;
    margin-bottom: 2rem;
    color: $white;
    margin-top: -0.5rem;
    @include tablet {
      color: $grey-darker;
    }

    &:before {
      background-color: $dark;
      content: '';
      display: block;
      width: calc(100% + #{$gap});
      height: calc(100% + 1rem);
      position: absolute;
      bottom: -0.5rem;
      z-index: -1;
      left: -#{$gap * 0.5};
      @include tablet {
        display: none;
      }
    }
  }

  .subtitle {
    position: relative;

    &:before {
      background-color: $grey-lightest;
      content: '';
      display: block;
      height: calc(100% + 1rem);
      width: calc(100% + #{$gap});
      position: absolute;
      bottom: -0.5rem;
      z-index: -1;
      left: -#{$gap * 0.5};
      @include tablet {
        display: none;
      }
    }
  }

  .hero--image-wrapper {
    margin: -1rem -#{$gap * 0.5};
    @include desktop {
      margin: -2rem -#{$gap - 1px} -2rem -#{$gap};
    }
  }

  .container {
    width: 100%;
  }

  .hero--overlay-wrapper.has-image .columns {
    margin-top: -3rem;
  }

  .hero--overlay-wrapper.has-image {
    width: 100%;
    margin-top: 3rem;
    position: relative;
    @include tablet {
      position: absolute;
      bottom: 0;
    }
    @include desktop {
      bottom: 30%;
    }
  }
}
