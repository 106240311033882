// 1. Import the initial variables.
@use "sass:math";

@import "../libraries/bulma/sass/utilities/initial-variables";
@import "utilities/initial-variables";

// 2. Set your own initial variables.
@import "utilities/variables";
@import "utilities/font-face";
@import "utilities/icomoon";

// 4. Import what we need of Bulma.
@import "../libraries/bulma/sass/utilities/_all";
@import "../libraries/bulma/sass/base/all";
@import "../libraries/bulma/sass/elements/title";
//@import "../libraries/bulma/sass/elements/table";
//@import "../libraries/bulma/sass/elements/button";
@import "../libraries/bulma/sass/elements/container";
@import "../libraries/bulma/sass/elements/content";
//@import "../libraries/bulma/sass/form/all";
//@import "../libraries/bulma/sass/elements/image";
//@import "../libraries/bulma/sass/components/media";
@import "../libraries/bulma/sass/components/navbar";
@import "../libraries/bulma/sass/components/tabs";
//@import "../libraries/bulma/sass/components/card";
//@import "../libraries/bulma/sass/components/level";
@import "../libraries/bulma/sass/grid/all";
@import "../libraries/bulma/sass/layout/section";
@import "../libraries/bulma/sass/layout/hero";

// 5. Import overrides and custom element definitions.
@import "base/generic";
@import "base/helpers";
@import "elements/title";
@import "elements/content";
//@import "elements/container";
@import "components/navbar";
@import "components/tabs";
@import "components/loader";
@import "grid/columns";
@import "layout/content";
@import "layout/footer";
@import "layout/hero";
@import "pages/front";

// 5. Import custom blueprint styling.
@import "blueprints/modular";
@import "blueprints/modular/modular--text";
@import "blueprints/modular/modular--list";
@import "blueprints/modular/modular--team";
@import "blueprints/modular/modular--columns";
@import "blueprints/modular/modular--text-two-columns";


.theme--none {

  margin-bottom: 0;

  .column {
    //padding-right: #{$gap / 4};
    //padding-left: #{$gap / 4};
    display: flex;
    align-items: center;

    width: 100%;
    flex-grow: 0;
    flex-basis: auto;
    @include tablet {
      padding-bottom: 0;
      width: inherit;
      max-width: 50%;
    }
    @include desktop {
      max-width: 660px;
      padding-bottom: 2rem;
    }

    &:not(:last-child) {
      padding-bottom: 0;
      @include desktop {
        padding-bottom: 2rem;
      }
    }
  }

  .icon svg {
    width: 40px;
    height: 40px;
  }
}

.icon {
  display: block;
  margin: 0 #{$gap * 0.25} 0 0;

  svg {
    width: 20px;
    height: 20px;
  }

  svg#default {
    width: 16px;
    margin-left: 24px;
    @include tablet {
      margin-left: 0;
    }
  }

  path {
    fill: $primary;
  }
}

*:focus {
  outline-color: $primary;
}

*::selection {
  background-color: $primary;
  color: #fff;
}

.is-front {
  img {
    @include desktop {
      width: 100%;
      //height: 100%;
    }
  }

  .front-gallery--image {
    padding-top: 1rem;
    //padding-bottom: 1rem;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      @include desktop {
        height: 100%;
      }
    }
  }

  @include desktop {
    .front-team--image {
      padding: 0 0 0 #{$gap*0.25};
    }
  }

  .front-gallery--image {
    padding: 1rem 0 0;

    &.is-offset-1-tablet {
      @include tablet {
        padding-right: #{$gap*0.25};
      }
    }

    &.is-2-tablet {
      padding-right: #{$gap*0.25};
      padding-left: #{$gap*0.25};
    }

    &:last-child {
      padding-left: #{$gap*0.25};
    }
  }
}


main .is-front,
main .modular--team {
  &.modular--is-first {
    &:before {
      @include desktop {
        max-width: percentage(math.div(10, 12));
      }
    }

    &:after {
      @include desktop {
        max-width: calc(#{percentage(math.div(10, 12))} - #{$column-gap * 0.5});
      }
    }
  }
}


.modular {
}

main.section {
  display: block;
  margin-top: 1rem;
  @include tablet {
    margin-top: 6rem;
  }
}

nav.section {
  margin-bottom: -1rem;
  @include tablet {
    margin-bottom: -6rem;
  }
}

main .modular {
  position: relative;

  &.modular--is-first {
    margin-top: 2rem;

    &:before {
      background-color: $dark;
      content: '';
      display: block;
      height: 1rem;
      width: 100%;
      position: absolute;
      top: -1rem;
      right: 0;
    }

    &:after {
      background-color: $primary;
      content: '';
      display: block;
      height: 1rem;
      width: calc(100% - #{$column-gap * 0.5});
      position: absolute;
      top: -2rem;
      right: 0;
    }
  }

  &.modular--empty {
    padding: 0;
  }

  &.modular--is-first.theme--light {
    margin-top: 3rem;

    &:before {
      top: -2rem;
    }

    &:after {
      top: -3rem;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 2rem;

    &:before {
      background-color: $dark;
      content: '';
      display: block;
      height: 1rem;
      width: 100%;
      position: absolute;
      bottom: -1rem;
      right: 0;
    }

    &:after {
      background-color: $primary;
      content: '';
      display: block;
      height: 1rem;
      width: calc(100% - #{$column-gap * 0.5});
      position: absolute;
      bottom: -2rem;
      right: 0;
    }
  }

  &.theme--dark:last-child {
    margin-bottom: 2rem;

    &:before {
      display: none;
    }

    &:after {
      bottom: -1rem;
    }
  }

}

img {
  vertical-align: bottom;
}

.section {
  padding: 1rem ($gap * 0.5);
  @include desktop {
    padding: 2rem $gap;
  }

  &.footer {
    padding: 1rem 0 0;
    @include desktop {
      padding: 2rem $gap 0;
    }
  }
}


.submenu {
  //border: solid 1px red;
  //padding: 2rem;
}

main .column.is-10 {
  position: relative;
}

main .column.is-10:first-child {
  //border-top: solid 10px $primary;

}


.to-bottom-container {
  position: static;
}

#toBottom, #toTop {
  display: none;
  position: absolute;
  z-index: 2;
  @include desktop {
    display: inline-block;
  }

  svg {
    height: 37px;
    width: 20px;
  }

  svg path {
    fill: $grey-dark;
    transition: $transition;
  }

  &:hover svg path {
    fill: $green;
  }
}

#toBottom {
  padding: #{math.div($gap, 1.5)};
  @include widescreen {
    padding: #{math.div($gap, 1.5)} 0;
  }
}

#toTop {
  right: -#{math.div($gap, 1.5)};
}

@import "_color-overrides.scss";
