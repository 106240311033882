// Hide Elements and show them when loader is done.
.hidden-on-load {
  opacity: 1;
  animation-name: fadeIn;
  animation-duration: 1s
}


.hidden-on-load-title {
  @include tablet {
    opacity: 1;
    animation-name: fadeIn;
    animation-duration: 4s;
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
