.title {
  font-family: $family-sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1px;

  .has-background-dark & {
    color: $white;
  }
}

.title:not(.is-spaced) + .subtitle {
  @include tablet {
    line-height: 1.8;
    margin-top: -1rem;
  }
}

.title.is-1 {
  font-size: $size-3;
  @include tablet {
    font-size: $size-1;
  }
}


.subtitle.is-3 {
  font-size: $size-5;
  @include tablet {
    font-size: $size-3;
  }
}
